<template>
  <b-card no-body class="max-w-xl">
    <b-card-header class="text-center">
      <b-card-title class="w-100">
        Une petite minute ! <br> Il reste des documents à approuver avant de jardiner...
      </b-card-title>
    </b-card-header>
    <b-card-body class="max-w-lg">
      <b-card-text v-if="gdprDocNotSigned">
        <GdprDocViewer :doc_type="gdprDocTypeToName(gdprDocNotSigned.doc_type)" :key="viewerKey" />
        <b-button block @click="signGdprDoc(gdprDocNotSigned.id)" :disabled="disableSign">
          Accepter le document
        </b-button>
      </b-card-text>
      <b-card-text v-else>
        Vous avez déjà approuvé les dernières versions des documents RGPD ! 
      </b-card-text>
    </b-card-body>
  </b-card>
</template>

<script>

import {BCard, BCardHeader, BCardTitle, BCardBody, BButton, BCardText} from "bootstrap-vue";
import GdprDocViewer from "@/views/gdpr/GdprDocViewer";

export default {
  name: 'GdprDocSignature',
  components: {
    GdprDocViewer,
    BCard,
    BCardHeader,
    BCardTitle,
    BButton,
    BCardBody,
    BCardText,
  },
  data() {
    return {
      gdprDocNotSigned: null,
      tabIndex: 0,
      viewerKey: 0,
      disableSign:false
    }
  },

  mounted() {
      if (this.$store.state.gdpr.gdprLastDocsNotSigned.length > 0) {
        this.gdprDocNotSigned = this.$store.state.gdpr.gdprLastDocsNotSigned[0]
      } else {
        this.gdprDocNotSigned = null
        this.$router.push({name: 'jayaccueil'})
      }
  },
  methods: {
    gdprDocTypeToName(doc_type) {
      return this.$store.state.gdpr.gdprDocTypes.find((type) => type.choice === doc_type).name
    },
    signGdprDoc(id) {
      this.$loading(true)
      this.disableSign = true
      const payload = {
        user: this.$store.state.account.user.id,
        gdpr_doc: id,
        date_created: new Date(),
      }
      this.$store.dispatch('gdpr/signGdprDoc', payload).
      then(() => {
        document.documentElement.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
        this.$notify({
          text: "La signature du document a bien été prise en compte",
          color: "primary",
          duration: 3000,
        });
        this.$loading(false)
        this.disableSign = false
        this.$store.dispatch('gdpr/fetchGdprLastDocsNotSigned').
        then(() => {
          this.viewerKey ++;
          if (this.$store.state.gdpr.gdprLastDocsNotSigned.length > 0) {
            this.gdprDocNotSigned = this.$store.state.gdpr.gdprLastDocsNotSigned[0]
          } else {
            this.gdprDocNotSigned = null
            this.$router.push({name: 'jayaccueil'})
          }
          this.viewerKey ++;
        })
        .catch(() => {
          this.$notify({
            title: "Erreur",
            text: "La récupération des documents non signés a échoué",
            color: "danger",
            duration: 3000,
          });
        })
      }).catch(() => {
        this.$loading(false)
        this.disableSign = false
        this.$notify({
          title: "Erreur",
          text: "La signature du document a échoué",
          color: "danger",
          duration: 3000,
        });
      })
    },
  }
}
</script>
